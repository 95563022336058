import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tag,
  Form,
  Select,
  Input,
  Empty,
  Row,
  Col,
  Alert,
  Divider,
} from "antd";
import Notification from "../../shared/Notification";
import API from "../../../api";
import { NumericFormat } from "react-number-format";
import ContactPopup from "./ContactPopup";
import GlobalContext from "../../../store/global-context";
import AccountConfirmationModal from "./AccountConfirmationModal";
import { encryptData, decryptData } from "../../../utils/helpers";
import TransactionForm from "../Transaction/TransactionForm";
import ResaleTransactionModal from "../Transaction/ResaleTransactionModal";

const { Option } = Select;

const TitleCalculator = () => {
  const navigate = useNavigate();
  // const { search } = useLocation();
  // const isNew = new URLSearchParams(search).get("new");
  const globalCtx = useContext(GlobalContext);
  const isStandalone = window.location.pathname.startsWith("/standalone");
  const [form] = Form.useForm();
  const [newRecord, setNewRecord] = useState({});
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setDownloadIsLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [uuid, setUuid] = useState(null);
  const [_, setDummyState] = useState(true);
  const [open, setOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isTransactionCreateModalOpen, setIsTransactionCreateModalOpen] =
    useState(false);
  const [isResaleTransactionModalOpen, setIsResaleTransactionModalOpen] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const contactData = JSON.parse(localStorage.getItem("SWBTitleContactInfo"));
  const localStorageToken = localStorage.getItem("standalone_token");
  const localStorageUser = localStorage.getItem("standalone_user")
    ? JSON.parse(localStorage.getItem("standalone_user"))
    : null;
  const canCreateTx =
    globalCtx?.user?.can_create_tx &&
    globalCtx?.user?.client?.feature_preference !== "title_calculator";
  const isResale = globalCtx?.user?.client?.isResale;

  useEffect(() => {
    if (
      localStorageToken &&
      localStorageUser?._id &&
      (localStorageUser?.client?.feature_preference
        ? localStorageUser?.client?.feature_preference !== "transaction"
        : true)
    ) {
      navigate("/standalone/title-calculator/quick");
      localStorage.removeItem("SWBTitleContactInfo");
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!contactData && response?.success && !isStandalone) {
        setOpen(true);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [response]);

  // useEffect(() => {
  //   if (isNew) {
  //     const payloadData = localStorage.getItem("SWBTitlePayload");
  //     if (payloadData) {
  //       form.resetFields(payloadData);
  //       findPricing(payloadData);
  //     }
  //     localStorage.removeItem("SWBTitlePayload");
  //     localStorage.removeItem("SWBTitleContactInfo");
  //   }
  // }, [isNew]);

  const getStates = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/get-state-county`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setOptions(result?.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    const breadCrumnArr = [
      {
        name: "Quick Calculator",
      },
    ];
    globalCtx.setBreadcrumb(breadCrumnArr);
    globalCtx.setActive("11");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      state: "",
      county: "",
      LoanOneAmount: "",
      PurchasePrice: "",
      LoanType: "",
      PropertyType: "",
      TransactionType: "",
    });
  }, []);

  const findPricing = async (data, contactId) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };
    if (!isStandalone && (contactId || contactData?.contact_info_uuid)) {
      payload.contact_info_uuid = contactId || contactData?.contact_info_uuid;
      const storedData = JSON.parse(
        localStorage.getItem("SWBTitleContactInfo")
      );
      if (storedData) payload.contact_info = { ...storedData };
    }
    if (!isStandalone) {
      payload = {
        data: await encryptData(payload, process.env.REACT_APP_ENCRYPTION_KEY),
      };
    }
    API.post(
      isStandalone ? "standalone/users/quote-calculator" : "rate-calculator",
      payload,
      {
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
      }
    )
      .then(async (result) => {
        let decryptedData = { ...(result?.data?.response || {}) };
        if (!isStandalone && result?.data?.response?.data) {
          decryptedData = await decryptData(
            result.data.response.data,
            process.env.REACT_APP_ENCRYPTION_KEY
          );
        }
        if (result?.data?.status) {
          if (+decryptedData?.failure_count === 1) {
            setResponse((old) => {
              return {
                success: false,
                Standard: {},
                AOL: {},
                aol_error: decryptedData?.bestx_response?.aol_error?.length
                  ? decryptedData?.bestx_response?.aol_error
                  : "",
                standard_error: decryptedData?.bestx_response?.standard_error
                  ?.length
                  ? decryptedData?.bestx_response?.standard_error
                  : "",
                aol_ineligible: decryptedData?.bestx_response?.aol_ineligible,
              };
            });
            setUuid(null);
            Notification("error", decryptedData?.failed);
          } else {
            setIsSubmitDisabled(true);
            setUuid(decryptedData?.bestx_response?.uuid || null);
            let aolData = decryptedData?.bestx_response?.AOL;
            if (decryptedData?.bestx_response?.Savings?.dollar > 0)
              aolData.Savings = decryptedData?.bestx_response?.Savings?.dollar;
            setResponse((old) => {
              return {
                success: true,
                Standard: decryptedData?.bestx_response?.Standard || {},
                AOL: decryptedData?.bestx_response?.AOL || {},
                aol_error: decryptedData?.bestx_response?.aol_error?.length
                  ? decryptedData?.bestx_response?.aol_error
                  : "",
                standard_error: decryptedData?.bestx_response?.standard_error
                  ?.length
                  ? decryptedData?.bestx_response?.standard_error
                  : "",
                aol_ineligible: decryptedData?.bestx_response?.aol_ineligible,
              };
            });
          }
        } else {
          setUuid(null);
          setResponse((old) => {
            return {
              success: false,
              Standard: {},
              AOL: {},
            };
          });
          Notification("error", decryptedData?.failed);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setUuid(null);
        setResponse((old) => {
          return {
            success: false,
            Standard: {},
            AOL: {},
          };
        });
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  };

  const atLeastOneFieldFilled = () => {
    const fields = form.getFieldsValue();
    const isAnyFieldFilled = Object.values(fields).some((field) => field);

    if (isAnyFieldFilled) {
      return false;
    }
    return true;
  };

  const downloadPdf = () => {
    setDownloadIsLoading(true);
    API.post(
      `download-rate-doc`,
      { uuid },
      {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
      }
    )
      .then((result) => {
        if (result?.data?.status && result?.data?.response?.doc_url) {
          const link = document.createElement("a");
          link.href = result?.data?.response?.doc_url;
          link.download = "title_rate.pdf";
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setDownloadIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setDownloadIsLoading(false);
      });
  };

  const handleSubmit = (data) => {
    let payload = {
      county: data?.county,
      state: data?.state,
      loan_type: data?.LoanType?.length ? data?.LoanType : "None",
      transaction_type: data?.TransactionType,
      property_type: data?.PropertyType,
      loan_amount1: data?.RawLoanAmount ? data?.RawLoanAmount : "",
      purchase_price: data?.RawPurchasePrice ? data?.RawPurchasePrice : "",
    };
    setFormData(payload);
    findPricing(payload);
  };

  return (
    <div className="content-layout title-calc-popup">
      <TransactionForm
        open={isTransactionCreateModalOpen}
        handleModalClose={() => setIsTransactionCreateModalOpen(false)}
        uuid={uuid}
        selectedProduct={selectedProduct}
      />
      <ResaleTransactionModal
        open={isResaleTransactionModalOpen}
        handleModalClose={() => {
          setIsResaleTransactionModalOpen(false);
        }}
        uuid={uuid}
        selectedProduct={selectedProduct}
      />
      <ContactPopup
        open={open}
        setOpen={setOpen}
        payload={formData}
        handleContinue={findPricing}
        setIsAccountModalOpen={setIsAccountModalOpen}
      />
      <AccountConfirmationModal
        open={isAccountModalOpen}
        setOpen={setIsAccountModalOpen}
        handleContinue={findPricing}
        formData={formData}
      />
      <Row gutter={10} style={{ height: "100%" }}>
        <Col xl={8} lg={8} md={8} sm={24}>
          {" "}
          <div className="title-cal-form">
            <Row className="title-calc-header">Inputs for calculator</Row>
            <Form
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              style={{ marginTop: "14px" }}
              form={form}
              layout="horizontal"
              name="title"
              className="title-form"
              scrollToFirstError
              onFinish={handleSubmit}
              onFieldsChange={() => {
                setIsSubmitDisabled(false);
              }}
            >
              <Row gutter={10}>
                <Col xl={24} lg={24} md={24} sm={24}>
                  {" "}
                  <Form.Item
                    // style={{ width: "200px" }}
                    name={"state"}
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please select state",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      value={newRecord?.State}
                      onChange={(value) => {
                        form.setFieldValue("state", value);
                        form.setFieldValue("county", "");
                        setDummyState((prev) => !prev);
                      }}
                      // onChange={(value) => {
                      //   setNewRecord((old) => {
                      //     return {
                      //       ...old,
                      //       State: value,
                      //       County: "",
                      //     };
                      //   });
                      // }}
                      filterOption={(input, option) =>
                        option?.full_name
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                    >
                      {Object?.keys(options?.states || {})?.map((opt) => (
                        <Option
                          key={opt}
                          value={opt}
                          full_name={options?.states?.[opt]?.full_name || opt}
                        >
                          {options?.states?.[opt]?.full_name || opt}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xl={24} lg={24} md={24} sm={24}>
                  <Form.Item
                    name={"county"}
                    label="County"
                    rules={[
                      {
                        required: true,
                        message: "Please select county",
                      },
                    ]}
                  >
                    <Select
                      // style={{ width: "200px" }}
                      allowClear
                      showSearch
                      value={newRecord?.County}
                      // onChange={(value) => {
                      //   setNewRecord({ ...newRecord, County: value });
                      // }}
                      onChange={(value) => {
                        setDummyState((prev) => !prev);
                      }}
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                    >
                      {options?.states?.[
                        form?.getFieldValue("state")
                      ]?.county?.map((opt) => (
                        <Option key={opt} value={opt}>
                          {opt}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xl={24} lg={24} md={24} sm={24}>
                  {" "}
                  <Form.Item
                    name={"PropertyType"}
                    label={"Property Type"}
                    rules={[
                      {
                        required: true,
                        message: "Please select Property Type",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      value={newRecord?.PropertyType}
                      // onChange={(value) => {
                      //   setNewRecord({ ...newRecord, PropertyType: value });
                      // }}
                      onChange={(value) => {
                        setDummyState((prev) => !prev);
                      }}
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                    >
                      {options?.property_type?.map((opt) => (
                        <Option key={opt} value={opt}>
                          {opt}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col xl={24} lg={24} md={24} sm={24} style={{ height: "20px" }}>
                <hr style={{ borderTop: "1px solid #fffcfc" }} />
              </Col>
              <Row gutter={10}>
                <Col xl={24} lg={24} md={24} sm={24}>
                  <Form.Item
                    name={"TransactionType"}
                    label={"Transaction Type"}
                    rules={[
                      {
                        required: true,
                        message: "Please select Transaction Type",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      value={newRecord?.TransactionType}
                      onChange={(value) => {
                        form.setFieldValue("TransactionType", value);
                        setDummyState((prev) => !prev);
                      }}
                      // onChange={(value) => {
                      //   setNewRecord({ ...newRecord, TransactionType: value });
                      // }}
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                    >
                      {[
                        {
                          label: "Purchase",
                          value: "Purchase",
                        },
                        {
                          label: "Refinance",
                          value: "Refinance",
                        },
                      ]?.map((opt) => (
                        <Option key={opt?.value} value={opt?.label}>
                          {opt?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col xl={24} lg={24} md={24} sm={24} style={{ height: "20px" }}>
                {" "}
                <hr style={{ borderTop: "1px solid #fffcfc" }} />
              </Col>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24}>
                  {" "}
                  <Form.Item
                    className={
                      form.getFieldValue("TransactionType") === "Refinance"
                        ? "disabled-field"
                        : ""
                    }
                    name={"PurchasePrice"}
                    label="Purchase Price"
                    rules={[
                      {
                        required:
                          form.getFieldValue("TransactionType") === "Purchase"
                            ? true
                            : false,
                        message: "Please select Purchase Price",
                      },
                    ]}
                  >
                    <NumericFormat
                      customInput={Input}
                      allowNegative={false}
                      onValueChange={(values) => {
                        form.setFieldValue("RawPurchasePrice", values.value);
                        setDummyState((prev) => !prev);
                      }}
                      thousandSeparator
                      valueIsNumericString
                      prefix="$"
                      disabled={
                        form.getFieldValue("TransactionType") === "Refinance"
                      }
                    />
                  </Form.Item>
                  <Form.Item name="RawPurchasePrice" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24}>
                  {" "}
                  <Form.Item
                    label={"Loan Amount"}
                    name={"LoanOneAmount"}
                    rules={[
                      {
                        required:
                          form.getFieldValue("TransactionType") === "Refinance"
                            ? true
                            : false,
                        message: "Please select Loan Amount",
                      },
                    ]}
                  >
                    <NumericFormat
                      customInput={Input}
                      allowNegative={false}
                      onValueChange={(values) => {
                        form.setFieldValue("RawLoanAmount", values.value);
                        setDummyState((prev) => !prev);
                      }}
                      thousandSeparator
                      valueIsNumericString
                      prefix="$"
                    />
                  </Form.Item>
                  <Form.Item name="RawLoanAmount" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24}>
                  {" "}
                  <Form.Item
                    name={"LoanType"}
                    label={"Loan Type"}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Loan Type",
                    //   },
                    // ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      value={newRecord?.LoanType}
                      // onChange={(value) => {
                      //   setNewRecord({ ...newRecord, LoanType: value });
                      // }}
                      onChange={(value) => {
                        setDummyState((prev) => !prev);
                      }}
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                    >
                      {options?.loan_type?.map((opt) => (
                        <Option key={opt} value={opt}>
                          {opt}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    className="cancel-btn"
                    disabled={atLeastOneFieldFilled()}
                    onClick={() => {
                      setNewRecord({});
                      setResponse({});
                      setUuid(null);
                      form.resetFields();
                      setIsSubmitDisabled(false);
                    }}
                    // loading={submitLoading}
                  >
                    Reset
                  </Button>{" "}
                  <Button
                    className="save-btn"
                    htmlType="submit"
                    loading={isLoading}
                    style={{ marginLeft: "8px" }}
                    disabled={isLoading || isSubmitDisabled}
                  >
                    {isStandalone ? "Submit & Save" : "Submit"}
                  </Button>
                </div>
              </Col>{" "}
              {isStandalone && response?.success ? (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  style={{
                    marginTop: "20px",
                    color: "#1976d2",
                    fontSize: "13px",
                  }}
                >
                  Your quote has been saved and can be viewed in the quote
                  history
                </Col>
              ) : (
                ""
              )}
              {Object.keys(contactData || {})?.length && !isStandalone ? (
                <>
                  <Col xl={24} lg={24} md={24} sm={24}>
                    <Divider
                      style={{
                        color: "#1976d2",
                        borderTopColor: "#cacaca",
                        marginTop: "16px",
                      }}
                    >
                      Contact Information
                    </Divider>
                  </Col>
                  <Row style={{ marginTop: "12px" }}>
                    <Col xl={8} lg={8} md={8} sm={8} className="fw-500">
                      Name :
                    </Col>
                    <Col xl={16} lg={16} md={16} sm={16}>
                      {contactData?.first_name} {contactData?.last_name}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "6px" }}>
                    {" "}
                    <Col xl={8} lg={8} md={8} sm={8} className="fw-500">
                      Email :
                    </Col>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={16}
                      style={{ wordWrap: "break-word" }}
                    >
                      {contactData?.email ? contactData.email : "-"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "6px" }}>
                    {" "}
                    <Col xl={8} lg={8} md={8} sm={8} className="fw-500">
                      Phone No :
                    </Col>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={16}
                      style={{ wordWrap: "break-word" }}
                    >
                      {contactData?.phone ? contactData?.phone : "-"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "6px" }}>
                    {" "}
                    <Col xl={8} lg={8} md={8} sm={8} className="fw-500">
                      Company :
                    </Col>
                    <Col Col xl={16} lg={16} md={16} sm={16}>
                      {" "}
                      {contactData?.company_name
                        ? contactData?.company_name
                        : "-"}
                    </Col>
                  </Row>
                  <Col
                    md={24}
                    sm={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/sign-up?client=vss-standard");
                      }}
                      className="save-btn"
                    >
                      Create an account{" "}
                    </Button>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Form>
          </div>
        </Col>
        <Col xl={16} lg={16} md={16} sm={24}>
          <div className="title-cal-form">
            <Row className="title-calc-header">
              <Col xl={12} lg={12} md={12} sm={12}>
                Title Quotes
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="btns-right">
                  {uuid ? (
                    <Button
                      className="cancel-btn"
                      onClick={downloadPdf}
                      loading={isDownloadLoading}
                      disabled={open}
                      style={{ marginLeft: "4px" }}
                    >
                      <i
                        class="fa-solid fa-download"
                        style={{ marginRight: "4px" }}
                      ></i>
                      Download
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col
                xl={12}
                lg={12}
                md={24}
                sm={24}
                style={{ marginTop: "14px" }}
              >
                {" "}
                <Table
                  loading={isLoading}
                  title={() => (
                    <div className="pricing-header">
                      <div>
                        {" "}
                        {globalCtx?.user?.client?.standard_product_type &&
                        globalCtx?.user?.client?.standard_product_type ===
                          "traditional_centralized_standard"
                          ? "Traditional Centralized Standard"
                          : "Traditional Standard"}{" "}
                        Pricing
                      </div>
                      {isStandalone && canCreateTx && uuid && (
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            setSelectedProduct("standard");
                            // resale module change
                            if (isResale) {
                              setIsResaleTransactionModalOpen(true);
                            } else {
                              setIsTransactionCreateModalOpen(true);
                            }
                          }}
                          type="link"
                        >
                          Place Order
                        </Button>
                      )}
                    </div>
                  )}
                  style={{ width: "98%" }}
                  scroll={{ x: "max-content" }}
                  dataSource={Object?.keys(response?.Standard || {}).map(
                    (val) => {
                      return {
                        type: val,
                        price: response?.Standard?.[val] ?? "-",
                      };
                    }
                  )}
                  columns={[
                    { title: "Type", dataIndex: "type" },
                    {
                      title: "Price",
                      dataIndex: "price",
                      render: (text) => (
                        <div>
                          {!open
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(text) === "$NaN"
                              ? text
                              : new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(text)
                            : "$ xxxx.xx"}
                        </div>
                      ),
                    },
                  ]}
                  locale={{
                    emptyText: (
                      <div className="empty-table-data">
                        {response?.standard_error?.length ? (
                          response?.standard_error
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </div>
                    ),
                  }}
                  pagination={false}
                  rowClassName={(record) =>
                    record?.type === "Savings"
                      ? "saving"
                      : record?.type === "Total"
                      ? "total"
                      : ""
                  }
                />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={24}
                sm={24}
                style={{ marginTop: "14px" }}
              >
                <Table
                  loading={isLoading}
                  title={() => (
                    <div className="aol-title pricing-header">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "8px" }}>AOL Pricing</div>
                        {response?.aol_ineligible ? (
                          <Tag className={"centered error"}>
                            {"AOL Ineligible"}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                      {isStandalone && canCreateTx && uuid && (
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            setSelectedProduct("aol");
                            // resale module change
                            if (isResale) {
                              setIsResaleTransactionModalOpen(true);
                            } else {
                              setIsTransactionCreateModalOpen(true);
                            }
                          }}
                        >
                          Place Order
                        </Button>
                      )}
                    </div>
                  )}
                  style={{
                    width: "98%",
                    // marginLeft: "16px",
                  }}
                  scroll={{ x: "max-content" }}
                  dataSource={Object?.keys(response?.AOL || {}).map((val) => {
                    return { type: val, price: response?.AOL?.[val] ?? "-" };
                  })}
                  locale={{
                    emptyText: (
                      <div className="empty-table-data">
                        {response?.aol_error?.length ? (
                          response?.aol_error
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </div>
                    ),
                  }}
                  columns={[
                    { title: "Type", dataIndex: "type" },
                    {
                      title: "Price",
                      dataIndex: "price",
                      render: (text) => (
                        <div>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(text) === "$NaN"
                            ? text
                            : new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(text)}
                        </div>
                      ),
                    },
                  ]}
                  pagination={false}
                  rowClassName={(record) =>
                    record?.type === "Savings"
                      ? "saving"
                      : record?.type === "Total"
                      ? "total"
                      : ""
                  }
                />
              </Col>
            </Row>
            <Alert
              message={
                <div>
                  <div className="alert-title">Disclaimer : </div>
                  <div
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    The above estimated fees do not include some
                    transaction-specific fees such as Recording fee, which only
                    can be determined when order is processed.
                  </div>
                  <div className="alert-title">For AOL Product : </div>
                  <div style={{ fontSize: "13px", marginTop: "10px" }}>
                    <div>
                      The loan should meet the following three requirements:
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      a. with a minimum FICO score of six hundred (600);
                      <br /> b. with a maximum combined loan to value of one
                      hundred percent (100%) <br />
                      c. that is a purchase money or refinance first Mortgage
                      Agreement
                    </div>
                  </div>
                  <div style={{ fontSize: "13px", marginTop: "10px" }}>
                    A property may have AOL disqualifiers such as co-op share
                    loans, purchase price or loan amount over $1M, loans secured
                    by a dwelling on a leasehold estate (including leasehold
                    estates on property owned by a community land trust), loans
                    secured by a manufactured home, Texas Section 50(a)(6)
                    loans, or loans executed using a power of attorney. Please
                    review property and loan information with your underwriter
                    for final approval before proceeding with an AOL.
                  </div>
                </div>
              }
              style={{ borderRadius: "5px", marginTop: "10px" }}
              type="info"
              // closable
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TitleCalculator;
